import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
import ContactForm from "../components/ContactForm"
import { withScriptjs, withGoogleMap ,GoogleMap, Marker } from "react-google-maps"
import MarkerIcon from "../images/casaitaliamarker_f.png"
import Fade from "react-reveal/Fade"
import {isEmpty} from "../util/helpers"

const styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ff0000"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ff0000"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]

const CasaItaliaMap = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={3}
    defaultCenter={{lat:42.6140862, lng: -54.9165887}}
    defaultOptions={{
      styles: styles
    }}
  >
    <Marker defaultIcon={MarkerIcon} position={{lat: 33.65677 , lng: -117.99616 }} />
    <Marker defaultIcon={MarkerIcon} position={{lat: 45.48958, lng: 9.20319}} />
  </GoogleMap>
))

const Contact = ({ data, className,location:{pathname:path}, pageContext }) => {
  // console.log("CONTACT PAGE CONTEXT", pageContext)
  const lang = isEmpty(pageContext) ? "en" : pageContext.lang
  // const { lang } = pageContext

  return (
    <Layout lang={lang} page={path}>
      <SEO title="Contact Us | Casa Italia" />
      <div className={className}>
        <div className="content content--contact content--inner" style={{paddingBottom:"50px"}}>
          <Fade top>
            <PageTitle>
              Contact <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Us
            </PageTitle>
          </Fade>
          <section className="section contact contact-page">
            <p>If you would like more information, please reach out using the form below</p>
            <ContactForm />
            <div className="contact__info">
              <span><i className="fas fa-envelope" /> <a
                href="mailto:info@casaitaliausa.com">info@casaitaliausa.com</a></span>
              <span><i className="fas fa-phone-alt" /> <a href="tel:312 731 6277">312 731 6277</a></span>
              <span><i className="fas fa-map-marker-alt" /> Los Angeles/Milan Office</span>
            </div>
            <div className="google-map" id="map">
              <CasaItaliaMap
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${data.webfront.mapsApiKey}&v=3.exp&libraries=geometry,drawing,place`}
                />
            </div>
          </section>

        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    webfront {
      mapsApiKey
    }
  }
`

export default styled(Contact)`
  background-color: #e9e9e9;
  .contact-page {
    max-width:1200px;
    margin:0 auto;
    span {
      font-family: BankGothicLtBTLight,sans-serif;
    }
  }
  .contact__info {
    padding:35px 0;
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    flex-shrink: 0;
    a {
      color: #000;
      transition: all .3s ease;
      // line-height:20px;
      // text-decoration: none;
      &:hover {
        cursor: pointer;
        color: red;
      }
    }
    span {
      display: flex;
      width:100%;
      justify-content: center;
      line-height: 20px;
      margin-bottom:20px;
      @media screen and (min-width:900px) {
        justify-content: initial;
        width:auto;
        margin-bottom:0;
      }
      font-family: 'Bankgothic Regular',sans-serif;
      font-size:12px;
      flex-shrink:0;
      i {
        font-size:18px;
      }
    }
  }
`
